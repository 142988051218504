import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PosicionesService {

    constructor(private http: HttpClient) { }

    getPosiciones(usuario, token, imei, dateStart, dateEnd): Promise<any> {

        /*
        Posiciones:
        {
            "usuario": "user",
            "token": "token",
            "imei": "imei",
            "dateStart" : "dateStart",
            "dateEnd" : "dateEnd"
        }
        */

        return new Promise((resolve, reject) => {

            let url = "/api/posiciones"
            let body = {
                usuario: usuario,
                token: token,
                imei: imei,
                dateStart: dateStart,
                dateEnd: dateEnd
            }

            this.http.post(url, body).toPromise().then(data => {
                console.log(data);
                if (data["error"]) {
                    reject(data["error"]);
                } else {
                    resolve(this.parsePosiciones(data));
                }
            });
        })
    }

    parsePosiciones(data) {
        let posiciones = [];
        for (let posicion of data) {
            posiciones.push({
                _id: posicion._id,
                imei: posicion.imei,
                lat: posicion.lat,
                lng: posicion.lng,
                velocidad: posicion.velocidad,
                fecha: posicion.fecha
            })
        }
        return posiciones;
    }
}
