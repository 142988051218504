import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient) {

    }

    login(usuario, password): Promise<any> {

        /*
        post(url: string, 
            body: any, 
            options: { 
                headers?: HttpHeaders | { [header: string]: string | string[]; }; 
                observe?: "body"; 
                params?: HttpParams | { [param: string]: string | string[]; }; 
                reportProgress?: boolean; 
                responseType: "arraybuffer"; 
                withCredentials?: boolean; }): 
                Observable<ArrayBuffer>
        */

        return new Promise((resolve, reject) => {

            let url = "/api/login"
            let body = {
                usuario: usuario,
                password: password
            }

            this.http.post(url, body).toPromise().then(data => {
                console.log(data);
                if (data["usuario"] && data["token"]) {

                    localStorage.setItem("usuario", data["usuario"]);
                    localStorage.setItem("token", data["token"]);

                    resolve();

                } else if (data["error"]) {
                    reject(data["error"]);
                }

            });
        })
    }
}
