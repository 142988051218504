import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class VehiculosService {

    constructor(private http: HttpClient) {

    }

    getVehicles(usuario, token): Promise<any> {

        /*
        post(url: string, 
            body: any, 
            options: { 
                headers?: HttpHeaders | { [header: string]: string | string[]; }; 
                observe?: "body"; 
                params?: HttpParams | { [param: string]: string | string[]; }; 
                reportProgress?: boolean; 
                responseType: "arraybuffer"; 
                withCredentials?: boolean; }): 
                Observable<ArrayBuffer>
        */

        return new Promise((resolve, reject) => {

            let url = "/api/vehiculos"
            let body = {
                usuario: usuario,
                token: token
            }

            this.http.post(url, body).toPromise().then(data => {
                console.log(data);
                if (data["error"]) {
                    reject(data["error"]);
                } else {
                    resolve(this.parseVehicles(data));
                }
            });
        })
    }

    parseVehicles(data) {
        let vehicles = [];
        for (let vehiculo of data) {
            vehicles.push({
                _id: vehiculo._id,
                imei: vehiculo.imei,
                matricula: vehiculo.matricula,
                km: vehiculo.km
            });
        }
        return vehicles;
    }
}
