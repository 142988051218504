import { Component, OnInit } from '@angular/core';

import { VehiculosService } from './../../service/vehiculos.service';
import * as moment from 'moment';
import { AlertasService } from 'src/app/service/alertas.service';

@Component({
    selector: 'app-alertas',
    templateUrl: './alertas.component.html',
    styleUrls: ['./alertas.component.css']
})
export class AlertasComponent implements OnInit {

    vehiculosList = [];
    rutasList = [];
    alertas = [];

    usuario = localStorage.getItem('usuario');
    token = localStorage.getItem('token');

    vehiculos = [];

    horas = [
        '00', '01', '02', '03', '04', '05', '06', '07',
        '08', '09', '10', '11', '12', '13', '14', '15',
        '16', '17', '18', '19', '20', '21', '22', '23'
    ];
    
    minutos = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

    form_imei = '';
    form_email = '';
    form_lunes = false;
    form_martes = false;
    form_miercoles = false;
    form_jueves = false;
    form_viernes = false;
    form_sabado = false;
    form_domingo = false;
    form_horaInicio = '00';
    form_minutosInicio = '00';
    form_horaFinal = '00';
    form_minutosFinal = '00';

    constructor(
        private vehiculosService: VehiculosService,
        private alertasService: AlertasService
    ) {

    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.vehiculosService.getVehicles(this.usuario, this.token).then(data => {
            this.vehiculosList = data;
            this.alertasService.getAlertas(this.usuario, this.token).then(data2 => {
                this.alertas = data2;
            });
        });
    }

    findMatricula(imei) {
        for (let vehiculo of this.vehiculosList) {
            if (imei == vehiculo.imei) {
                return vehiculo.matricula;
            }
        }
        return "";
    }

    eliminar(alertaEliminar) {
        this.alertasService.deleteAlerta(this.usuario, this.token, alertaEliminar._id);
        let newAlertas = this.alertas.filter(alerta => alerta != alertaEliminar);
        this.alertas = newAlertas;
    }

    crear() {
        this.alertasService.createAlertas(
            this.usuario, 
            this.token,
            this.form_imei,
            this.form_email,
            this.form_lunes,
            this.form_martes,
            this.form_miercoles,
            this.form_jueves,
            this.form_viernes,
            this.form_sabado,
            this.form_domingo,
            this.form_horaInicio,
            this.form_minutosInicio,
            this.form_horaFinal,
            this.form_minutosFinal
        ).then(data => {
            this.loadData();
        })
    }

    momentFormat(date) {
        return moment(date).format('DD-MM-YY HH:mm');
    }

    momentFormatInfoWindow(date) {
        return moment(date).format('DD-MM-YYYY HH:mm:ss');
    }
}