import { AuthService } from './../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    showLoginError: boolean = false;
    usuario = "";
    password = "";

    constructor(
        private router: Router,
        private authService: AuthService
    ) {

    }

    ngOnInit() {

    }

    login() {
        if (this.usuario == "" || this.password == "") {
            alert("Introduce todos los datos");
            return;
        }
        this.authService.login(this.usuario, this.password).then(data => {
            this.router.navigateByUrl("/mapa");
        }).catch(err => {
            alert(err);
        });
    }

}
