import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AlertasService {

    constructor(private http: HttpClient) {

    }

    getAlertas(usuario, token): Promise<any> {

        return new Promise((resolve, reject) => {

            let url = "/api/alertas"
            let body = {
                usuario: usuario,
                token: token
            }

            this.http.post(url, body).toPromise().then(data => {
                console.log(data);
                if (data["error"]) {
                    reject(data["error"]);
                } else {
                    resolve(this.parseAlertas(data));
                }
            });
        })
    }

    parseAlertas(data) {
        let alertas = [];
        for (let alerta of data) {
            alertas.push({
                _id: alerta._id,
                usuario: alerta.usuario,
                imei: alerta.imei,
                email: alerta.email,
                lunes: alerta.lunes,
                martes: alerta.martes,
                miercoles: alerta.miercoles,
                jueves: alerta.jueves,
                viernes: alerta.viernes,
                sabado: alerta.sabado,
                domingo: alerta.domingo,
                horaInicio: alerta.horaInicio,
                minutosInicio: alerta.minutosInicio,
                horaFinal: alerta.horaFinal,
                minutosFinal: alerta.minutosFinal
            });
        }
        return alertas;
    }

    createAlertas(
        usuario, 
        token,
        form_imei,
        form_email,
        form_lunes,
        form_martes,
        form_miercoles,
        form_jueves,
        form_viernes,
        form_sabado,
        form_domingo,
        form_horaInicio,
        form_minutosInicio,
        form_horaFinal,
        form_minutosFinal
    ): Promise<any> {

        return new Promise((resolve, reject) => {

            let url = "/api/alertas_insert"
            let body = {
                usuario: usuario,
                token: token,
                imei: form_imei,
                email: form_email,
                lunes: form_lunes,
                martes: form_martes,
                miercoles: form_miercoles,
                jueves: form_jueves,
                viernes: form_viernes,
                sabado: form_sabado,
                domingo: form_domingo,
                horaInicio: form_horaInicio,
                minutosInicio: form_minutosInicio,
                horaFinal: form_horaFinal,
                minutosFinal: form_minutosFinal
            }

            this.http.post(url, body).toPromise().then(data => {
                console.log(data);
                if (data["error"]) {
                    reject(data["error"]);
                } else {
                    resolve(this.parseAlertas(data));
                }
            });
        })
    }

    deleteAlerta(
        usuario, 
        token,
        _id
    ): Promise<any> {

        return new Promise((resolve, reject) => {

            let url = "/api/alertas_delete"
            let body = {
                usuario: usuario,
                token: token,
                _id: _id
            }

            this.http.post(url, body).toPromise().then(data => {
                console.log(data);
                if (data["error"]) {
                    reject(data["error"]);
                } else {
                    resolve(this.parseAlertas(data));
                }
            });
        })
    }
}
