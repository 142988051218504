
/// <reference types="@types/googlemaps" />

import { Component, OnInit, ViewChild } from '@angular/core';

import { VehiculosService } from './../../service/vehiculos.service';
import { PosicionesService } from './../../service/posiciones.service';
import * as moment from 'moment';

@Component({
    selector: 'app-mapa',
    templateUrl: './mapa.component.html',
    styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
    @ViewChild('gmap') gmapElement: any;

    map: google.maps.Map;

    vehiculosList = [];
    rutasList = [];

    selectedImeis = [];
    selectedFechaInicial = moment().format('YYYY-MM-DD');
    selectedHoraInicial = '00:00';
    selectedFechaFinal = moment().format('YYYY-MM-DD');
    selectedHoraFinal = '23:59';
    selectedRangoHorarioInicio;
    selectedRangoHorarioFinal;
    selectedTiempoParada = 30;

    usuario = localStorage.getItem('usuario');
    token = localStorage.getItem('token');

    vehiculos = [];

    visibilidadTodasRutas = true;

    selectedInfoWindowVisible = true;

    infoWindow;

    constructor(
        private vehiculosService: VehiculosService,
        private posicionesService: PosicionesService
    ) {

    }

    ngOnInit() {
        setTimeout(() => {
            this.infoWindow = new google.maps.InfoWindow();
            
            let mapProp = {
                center: new google.maps.LatLng(42.5735672, -5.5671588),
                zoom: 10,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

            let myThis = this;
            this.map.addListener('mousemove', function(event) {
                myThis.pintaInfoWindow(event);
            });
        }, 100);

        this.loadVehicleList();

    }

    pintaInfoWindow(event) {
        if (this.selectedInfoWindowVisible) {
            let marcadorCercano = this.findMarcadorCercano(event.latLng.lat(), event.latLng.lng());

            if (!marcadorCercano) {
                return null;
            }

            let content = `
                Vehiculo: ${this.findMatricula(marcadorCercano.vehiculo.imei)} <br/>
                Velocidad: ${this.getVelocidad(marcadorCercano)} km/h<br/>
                Fecha: ${this.momentFormatInfoWindow(marcadorCercano.coordenada.fecha)}
            `;

            this.infoWindow.setContent(content);
            this.infoWindow.setPosition({lat: marcadorCercano.coordenada.lat, lng: marcadorCercano.coordenada.lng});
            this.infoWindow.open(this.map);
        } else {
            try {
                this.infoWindow.close();
            } catch (e) {
                console.error("Error cerrando infowindow");
                console.error(e);
            }
        }        
    }

    findMarcadorCercano(lat, lng) {
        if (this.rutasList.length == 0) {
            return null;
        }

        let minima;

        let marcador;

        for (let ruta of this.rutasList) {
            if (ruta.polyline.getVisible()) {

                /* ruta:
                vehiculo: vehiculo,
                polyline: polyline,
                path: path,
                color: randomColor
                */

                for (let coord of ruta.path) {
                    let distancia = this.distancia(coord.lat, coord.lng, lat, lng)
                    if (!minima || distancia < minima) {
                        minima = distancia;

                        marcador = {
                            vehiculo: ruta.vehiculo,
                            coordenada: coord
                        };
                    }
                }
            }
        }

        return marcador;
    }

    distancia(a_lat, a_lng, b_lat, b_lng) {
        return Math.sqrt((a_lat - b_lat) * (a_lat - b_lat) + (a_lng - b_lng) * (a_lng - b_lng));
    }

    loadVehicleList() {
        this.vehiculosService.getVehicles(this.usuario, this.token).then(data => {
            this.vehiculosList = data;
        });
    }

    busqueda() {

        //Limpieza:
        this.vehiculos = [];
        for (let i = 0; i < this.rutasList.length; i++) {
            try {
                this.rutasList[i].polyline.setMap(null);
                this.rutasList[i].polyline.remove();
            } catch(err) {
                console.error('Error en limpieza de rutas');
                console.error(err);
            }
        }
        this.rutasList = [];

        if (!this.selectedImeis
            || !this.selectedFechaInicial
            || !this.selectedHoraInicial
            || !this.selectedFechaFinal
            || !this.selectedHoraFinal) {

            alert("Datos incompletos en el formulario");
            return;
        }

        let dateStart = moment(this.selectedFechaInicial);
        dateStart.hour(parseInt(this.selectedHoraInicial.substring(0, 2)));
        dateStart.minute(parseInt(this.selectedHoraInicial.substring(3, 5)));
        dateStart.second(0);
        dateStart.millisecond(0);

        let dateEnd = moment(this.selectedFechaFinal);
        dateEnd.hour(parseInt(this.selectedHoraFinal.substring(0, 2)));
        dateEnd.minute(parseInt(this.selectedHoraFinal.substring(3, 5)));
        dateEnd.second(0);
        dateEnd.millisecond(0);

        console.log(this.selectedHoraInicial);
        console.log(this.selectedFechaFinal);
        console.log(dateStart.toISOString());
        console.log(dateEnd.toISOString());

        let descargasPosiciones = [];

        for (let imei of this.selectedImeis) {
            let km = false;
            for (let vehiculo of this.vehiculosList) {
                if (vehiculo.imei == imei && vehiculo['km']) {
                    km = true;
                    break;
                }
            }

            descargasPosiciones.push(
                this.posicionesService.getPosiciones(
                    this.usuario,
                    this.token,
                    imei,
                    dateStart.toISOString(),
                    dateEnd.toISOString()
                ).then(data => {
                    console.log('datos descargados de posiciones:')
                    console.log(data);

                    for (let posicion of data) {
                        posicion.lat = parseFloat(posicion.lat);
                        posicion.lng = parseFloat(posicion.lng);
                    }

                    this.vehiculos.push({
                        imei: imei,
                        posiciones: data,
                        km: km
                    })
                })
            );
        }

        Promise.all(descargasPosiciones).then(data => {
            this.generateRutas();
        })
    }

    findMatricula(imei) {
        for (let vehiculo of this.vehiculosList) {
            if (imei == vehiculo.imei) {
                return vehiculo.matricula;
            }
        }
        return "";
    }

    generateRutas() {

        for (let vehiculo of this.vehiculos) {

            let path = [];

            let lastPosicion = null;

            for (let posicion of vehiculo.posiciones) {
                if (this.posicionEnRangoHorario(posicion)) {

                    //Si ha estado parado mas de 30 minutos
                    let tiempoParado = 30;
                    if (this.selectedTiempoParada && this.selectedTiempoParada > 0) {
                        tiempoParado = this.selectedTiempoParada;
                    }

                    if (lastPosicion && (moment(posicion.fecha).diff(moment(lastPosicion.fecha)) > tiempoParado * 60 * 1000)) {
                        if (path.length > 4) {
                            this.createPolyline(path, vehiculo);                            
                        }
                        path = [];
                    }

                    path.push(posicion);

                    lastPosicion = posicion;
                }
            }

            //Acaba, genera con las que sobran
            if (path.length > 4) {
                this.createPolyline(path, vehiculo);
            }
        }

        if (this.rutasList.length == 0) {
            alert("No hay posiciones para los parámetros de búsqueda introducidos");
        } else {
            this.setCenterMap();
        }
    }

    createPolyline(path, vehiculo) {
        let randomColor = this.getRandomColor();

        let polyline = new google.maps.Polyline({
            path: path,
            //strokeColor: '#FF0000',
            strokeColor: randomColor,
            strokeOpacity: 1.0,
            strokeWeight: 2
        });

        polyline.setMap(this.map);

        this.rutasList.push({
            vehiculo: vehiculo,
            polyline: polyline,
            path: path,
            color: randomColor
        });
    }

    posicionEnRangoHorario(posicion) {

        if (posicion.velocidad == 0) {
            return false;
        }

        if (!this.selectedRangoHorarioInicio || !this.selectedRangoHorarioFinal) {
            return true;
        }

        let fechaPosicion = moment(posicion.fecha);

        let fechaPosicionStart = moment(posicion.fecha);
        fechaPosicionStart.hour(this.selectedRangoHorarioInicio.substring(0, 2));
        fechaPosicionStart.minute(this.selectedRangoHorarioInicio.substring(3, 5));

        let fechaPosicionEnd = moment(posicion.fecha);
        fechaPosicionEnd.hour(this.selectedRangoHorarioFinal.substring(0, 2));
        fechaPosicionEnd.minute(this.selectedRangoHorarioFinal.substring(3, 5));

        return (fechaPosicion.diff(fechaPosicionStart) >= 0
            &&
            fechaPosicion.diff(fechaPosicionEnd) <= 0);

    }

    changeAllRutasVisibility() {
        for (let ruta of this.rutasList) {
            ruta.polyline.setVisible(!this.visibilidadTodasRutas);
        }
        this.visibilidadTodasRutas = !this.visibilidadTodasRutas;
    }

    changeInfoWindowVisibility(option) {
        this.selectedInfoWindowVisible = option;
    }

    changeRutaVisibility(ruta) {
        ruta.polyline.setVisible(!ruta.polyline.getVisible());
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    setCenterMap() {
        let minLat;
        let maxLat;
        let minLng;
        let maxLng;

        for (let ruta of this.rutasList) {
            for (let posicion of ruta.path) {
                if (!minLat || minLat > posicion.lat) {
                    minLat = posicion.lat;
                }
                if (!maxLat || maxLat < posicion.lat) {
                    maxLat = posicion.lat;
                }
                if (!minLng || minLng > posicion.lng) {
                    minLng = posicion.lng;
                }
                if (!maxLng || maxLng < posicion.lng) {
                    maxLng = posicion.lng;
                }
            }
        }

        this.map.setCenter({
            lat: (minLat + maxLat) / 2,
            lng: (minLng + maxLng) / 2,
        })
    }

    momentFormat(date) {
        return moment(date).format('DD-MM-YY HH:mm');
    }

    momentFormatInfoWindow(date) {
        return moment(date).format('DD-MM-YYYY HH:mm:ss');
    }

    getVelocidad(marcadorCercano) {
        if (marcadorCercano.vehiculo['km']) {
            //console.error('Vehiculo en km');
            return (parseFloat(marcadorCercano.coordenada.velocidad)).toFixed(2);
        } else {
            //console.error('Vehiculo en millas');
            return (parseFloat(marcadorCercano.coordenada.velocidad) * 1.852).toFixed(2);
        }
    }
}