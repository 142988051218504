import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';
import { LoginComponent } from './component/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { MapaComponent } from './component/mapa/mapa.component';
import { PrincipalComponent } from './component/principal/principal.component';

import { HttpClientModule } from '@angular/common/http'; 
import { AlertasComponent } from './component/alertas/alertas.component';

const appRoutes: Routes = [
    { path: '', component: PrincipalComponent, pathMatch: 'full'},
    { path: 'login', component: LoginComponent },
    { path: 'mapa', component: MapaComponent },
    { path: 'alertas', component: AlertasComponent },
    /*{ path: 'hero/:id',      component: HeroDetailComponent },
    {
      path: 'heroes',
      component: HeroListComponent,
      data: { title: 'Heroes List' }
    },
    { path: '',
      redirectTo: '/heroes',
      pathMatch: 'full'
    },
    { path: '**', component: PageNotFoundComponent }*/
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MapaComponent,
        AlertasComponent,
        PrincipalComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: true } // <-- debugging purposes only
        )
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
